
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.5em;
  }

  h2 {
    text-align: center;
  }

  .heading-segment {
    display: block;
    line-height: 1.5em;
  }

  .chapter-heading .heading-segment--2 {
    font-size: 0.9em;
  }

  .chapter-heading .heading-segment--3 {
    font-size: 0.8em;
  }

  .chapter-heading__sequence {
    font-weight: 400;
  }

  .chapter-heading__title {
    text-align: center;
    font-weight: 700;
  }

  .style-blurb .chapter-heading h2 {
    font-weight: normal;
    font-style: italic;
    text-transform: none;
  }

  .chapter-subtitle--blurb {
    font-weight: normal;
    font-style: italic;
    text-transform: none;
    text-align: center;
    margin: -1em 0 2.25em;
  }

  h3 + h4 {
    margin-top: 0;
  }

  .epigraphs {
    margin-top: 30%;
    text-align: center;
    page: epigraphs;
  }

  .epigraph__text::before {
    content: '“';
  }

  .epigraph__text::after {
    content: '”';
    margin-left: -0.25em;
  }

  @page epigraphs {
    @bottom-center {
      content: normal;
    }
  }

  .epigraph {
    font-style: italic;
  }

  .epigraph__source {
    font-size: 0.9em;
    white-space: nowrap;
  }

  .epigraph__source::before {
    content: '(';
  }

  .epigraph__source::after {
    content: ')';
  }

  .epigraph--not-first::before {
    content: '*\00a0\00a0\00a0\00a0\00a0*\00a0\00a0\00a0\00a0\00a0*';
    margin-top: 2.5em;
    padding-bottom: 2em;
    display: block;
  }

  .poetry {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    font-style: italic;
    text-align: center;
    font-size: 95%;
  }

  .verse-stanza {
    display: block;
  }

  .verse-stanza + .verse-stanza {
    margin-top: 1.4em;
  }

  .verse-line {
    display: block;
    line-height: 1.6em;
  }

  .letter-heading {
    margin: 2.6em 0 0.6em;
    text-transform: uppercase;
    font-size: 0.85em;
    font-weight: 700;
    text-align: center;
    text-indent: 0;
  }

  /* technically not necessary :) */
  .no-indent {
    text-indent: 0;
    text-align: justify;
  }

  /* blockquotes */
  figcaption > *:first-child::before {
    content: '\2014';
  }

  /* chicago manual of style says first para of blockquote should not be indented */
  blockquote .paragraph:first-of-type {
    text-indent: 0;
  }

  blockquote.scripture p {
    font-style: italic;
  }

  figure.attributed-quote {
    margin: 0;
  }

  figure.attributed-quote figcaption cite {
    display: block;
  }

  h3.blurb,
  h4.blurb,
  p.blurb {
    font-weight: 400;
    font-style: italic;
    text-align: center;
  }

  /* emphasis within emphasis */
  .style-blurb .chapter-heading .book-title,
  blockquote.scripture em,
  .blurb .book-title,
  .emphasized .book-title,
  .syllogism em,
  .emphasized em,
  .blurb em,
  .emphasized em,
  .section-author em,
  .section-summary-preface .book-title,
  .section-author-context .book-title,
  .heading-continuation-blurb .book-title,
  .section-summary-preface em,
  .section-author-context em,
  .heading-continuation-blurb em {
    font-variant: small-caps;
    font-style: normal;
    font-size: 1.07em;
    padding-left: 0.1em;
    hyphens: none !important;
  }

  .chapter-synopsis {
    list-style: none;
    font-style: italic;
    text-align: center;
    font-size: 0.85em;
    padding: 0;
    margin: 0 1.5em 2em 1.5em;
  }

  .chapter-synopsis li {
    display: inline;
  }

  .chapter-synopsis li + li::before {
    content: '\2014';
    display: inline-block;
  }

  .offset,
  .paragraph.offset {
    text-indent: 0;
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: justify;
  }

  .numbered p:first-child {
    margin-bottom: 0;
    margin-top: 1em;
    text-indent: 0;
  }

  .numbered-group {
    margin-bottom: 1em;
  }

  .discourse-part {
    margin-bottom: 0;
    text-align: justify;
  }

  .small-break {
    height: 1em;
  }

  .the-end {
    text-transform: uppercase;
    margin-top: 3em;
    text-align: center;
  }

  .old-style {
    margin-top: 2.75em;
    text-align: center;
  }

  .old-style .heading-segment--1 {
    margin-bottom: 0.5em;
  }

  .old-style .heading-segment {
    display: block;
    line-height: 1.5em;
  }

  .old-style .heading-segment + .heading-segment {
    font-weight: 400;
    font-style: italic;
  }

  .old-style.bold .heading-segment + .heading-segment {
    font-weight: 700;
    font-style: normal;
  }

  .old-style.bold .heading-segment + .heading-segment em {
    font-style: normal;
  }

  h3.alt,
  h4.alt {
    font-weight: 400;
    font-style: italic;
  }

  .centered {
    text-align: center !important;
    text-indent: 0 !important;
  }

  .emphasized {
    font-style: italic;
  }

  .weight-normal {
    font-weight: 400;
  }

  .small-caps {
    font-variant: small-caps;
  }

  /* definition lists */
  dl {
    margin-top: 1.5em;
  }

  dd {
    margin: 1em 0 1em 2.85em;
    text-align: justify;
  }

  dt {
    font-weight: 700;
  }
  /* END definition lists */

  .embedded-content-document {
    margin: 1.35em 0 1.35em;
  }

  .half-title-page .editor {
    font-style: italic;
    text-align: center;
    font-size: 0.65em;
  }

  .book-title {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
  }

  .syllogism {
    padding-left: 0;
    margin-left: 2.5em;
  }

  .syllogism li {
    font-style: italic;
    margin: 1em 0;
  }

  .heading-continuation-blurb,
  .section-summary-preface {
    font-style: italic;
    padding-left: 1.5em;
    text-indent: -1.5em;
    text-align: justify;
  }

  .heading-continuation-blurb {
    margin-bottom: 1.75em;
    font-size: 0.95em;
  }

  .section-summary-preface {
    margin-bottom: 1em;
  }

  .heading-continuation-blurb + .heading-continuation-blurb {
    margin-top: -0.75em;
  }

  .section-author,
  .section-author-context {
    font-style: italic;
    text-align: center !important;
    text-indent: 0 !important;
  }

  .section-author-context {
    margin-top: 0.65em;
    margin-bottom: 1.75em;
    font-size: 90%;
  }

  .section-author + .heading-continuation-blurb,
  .section-author-context + .heading-continuation-blurb {
    margin-top: 1.5em;
  }

  .section-date {
    margin: 1.5em 0;
    text-align: center !important;
    text-indent: 0 !important;
  }

  .section-date::before {
    content: '[';
    font-style: italic;
    padding-right: 0.1em;
  }

  .section-date::after {
    content: ']';
    font-style: italic;
    margin-left: -0.2em;
  }

  blockquote.section-epigraph {
    margin-left: 1.5em;
    margin-right: 1.5em;
    border-left-width: 0;
  }

  blockquote.section-epigraph .paragraph {
    font-size: 0.9em;
    padding-left: 0;
  }

  h3.inline,
  h4.inline,
  h5.inline {
    padding-left: 1.5em;
    font-size: 1em;
    text-indent: -1.5em;
    margin: 1.333em 0;
    text-align: justify;
  }

  h3.inline em,
  h4.inline em,
  h5.inline em {
    font-variant: small-caps;
    font-style: normal;
    font-size: 1.07em;
    hyphens: none !important;
  }

  .footnote-paragraph + .footnote-paragraph {
    display: block;
    margin-top: 0.5em;
  }

  .footnote .poetry {
    margin-top: 0.4em;
    margin-bottom: 0.4em;
  }

  .footnote .verse-stanza {
    margin-bottom: 0;
  }

  .footnote .verse-stanza + .verse-stanza {
    margin-top: 0.45em;
  }

  .footnote .verse-line {
    line-height: 1.2em;
  }

  h3 + .letter-heading {
    margin-top: 1em;
  }

  h3 + h4 {
    margin-top: -0.75em;
  }

  h3 + h4.old-style {
    margin-top: 0.75em;
  }

  h3 + h3,
  h4 + h4 {
    margin-top: -0.5em;
  }



  .signed-section-context-open {
    margin-top: 2em;
    margin-bottom: 0;
  }

  .signed-section-context-open + .paragraph {
    margin-top: 0.75em;
  }

  .signed-section-context-open,
  .signed-section-context-close {
    font-style: italic;
    font-size: 0.9em;
  }

  .signed-section-context-close {
    margin-bottom: 2.5em;
  }

  .signed-section-context-close + .signed-section-signature {
    margin-top: -1.5em;
  }

  .signed-section-closing {
    text-align: right;
    margin-top: 0.5em;
  }

  .signed-section-closing + .signed-section-signature {
    margin-top: -0.5em;
  }

  .salutation {
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .signed-section-context-open + .salutation {
    margin-top: 0.75em;
  }

  .signed-section-context-open + .signed-section-context-open {
    margin-top: 0.1em;
  }

  .signed-section-signature {
    margin-top: 0.45em;
    margin-bottom: 2.5em;
    padding-left: 3em;
    font-variant: small-caps;
  }

  .signed-section-signature::before {
    content: '\2014';
  }

  .paragraph + .postscript {
    margin-top: 1em;
  }

  .signed-section-context-close + .postscript,
  .signed-section-signature + .postscript {
    margin-top: -1.85em;
  }

  .postscript .paragraph:first-of-type {
    text-indent: 0;
  }

  .signed-section-signature,
  .signed-section-context-close {
    text-align: right;
    text-indent: 0;
  }

  .salutation {
    margin-bottom: 0.5em;
  }

  .letter-heading + .signed-section-context-open {
    margin-top: 0.5em;
  }

  .signed-section-context-close + .signed-section-context-close,
  .signed-section-signature + .signed-section-signature {
    margin-top: -2.5em;
  }

  .signed-section-signature + .signed-section-context-close {
    margin-top: -2.25em;
  }

  .offset + .embedded-content-document {
    margin-top: -0.5em;
  }

  .offset + .embedded-content-document .letter-heading {
    margin-top: 1.75em;
  }



  table.tableblock {
    width: 100%;
    margin: 1em 0;
    border-collapse: collapse;
  }

  table.tableblock p.tableblock {
    line-height: 115%;
    margin: 0;
  }

  table.tableblock th + th,
  table.tableblock td + td {
    padding-left: 1em;
  }

  table.tableblock.table-vertical-lines th + th,
  table.tableblock.table-vertical-lines td + td {
    padding: 0 0.2em;
  }

  table.tableblock.table-vertical-lines td:first-child,
  table.tableblock.table-vertical-lines th:first-child {
    padding-right: 0.2em;
  }

  table.tableblock.table-vertical-lines td:last-child,
  table.tableblock.table-vertical-lines th:last-child {
    padding-left: 0.2em;
  }

  table.tableblock.table-vertical-lines tr:last-child,
  table.tableblock.table-vertical-lines thead {
    border-bottom-width: 0;
  }

  table.tableblock.table-vertical-lines td + td {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }

  table.tableblock tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    vertical-align: bottom;
  }

  table.tableblock td,
  table.tableblock th {
    vertical-align: bottom;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }

  table.tableblock.table-valign-middle td,
  table.tableblock.table-valign-middle th {
    vertical-align: middle;
  }

  table.tableblock tr:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.75);
  }

  table.tableblock thead {
    padding-bottom: 0.25em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.75);
  }

  table.tableblock + table.tableblock {
    margin-top: 2em;
  }

  table.tableblock.table-align-center td,
  table.tableblock.table-align-center td > *,
  table.tableblock.table-tail-align-center td,
  table.tableblock.table-tail-align-center th {
    text-align: center;
  }

  table.tableblock.table-tail-align-right td,
  table.tableblock.table-tail-align-right th {
    text-align: right;
  }

  table.tableblock.table-tail-align-center td:first-child,
  table.tableblock.table-tail-align-center th:first-child,
  table.tableblock.table-tail-align-right td:first-child,
  table.tableblock.table-tail-align-right th:first-child {
    text-align: left;
  }

  table.tableblock.table-header-tail-x-small th {
    font-size: 0.74em;
  }

  table.tableblock.table-header-tail-x-small th:first-child {
    font-size: inherit;
  }

  table.tableblock p.tableblock + p.tableblock {
    margin-top: 1em;
  }

  table.tableblock.table-last-col-secondary td:last-child {
    font-size: 0.85em;
    font-style: italic;
  }



  @page {
    

    @bottom-center {
      content: counter(page);
      font-size: 0.9rem;
      padding: 0 !important;
      vertical-align: text-top;
      margin-top: 5pt;
    }

    @top-center {
      margin-top: 0.35in;
      font-size: 0.9rem;
      font-style: italic;
    }
  }

  @page:left {
    @top-center {
      content: "Preview";
    }
  }

  @page:right {
    @top-center {
      content: string(chapter, last);
    }
  }

  @page:first {
    @top-center {
      content: normal;
    }
  }

  .letter-heading {
    -webkit-hyphens: none !important;
    hyphens: none !important;
  }

  .body {
    font-family: Georgia, 'Bitstream Charter', serif;
  }

  div.asterism {
    text-align: center;
    letter-spacing: 0.15em;
    vertical-align: middle;
    line-height: 2em;
    margin: 1em 0 0.6em 0;
  }

  .paragraph {
    text-indent: 1.5em;
    text-align: justify;
    margin: 0;
    hyphens: auto;
  }

  .own-page {
    page-break-before: always;
    page-break-after: always;
  }

  #footnotes {
    display: none;
  }

  .chapter {
    counter-reset: footnote;
  }

  .__fn_ignore__-call {
    content: counter(footnote);
    vertical-align: super;
    line-height: none;
  }

  .__fn_ignore__-marker {
    content: counter(footnote);
    padding-right: 7pt;
    opacity: 0.9;
  }

  .footnote {
    float: footnote;
    padding-top: 0.5em;
    text-indent: 0;
    text-align: justify !important;
    font-weight: 400;
    text-transform: none !important;
    font-variant: normal !important;
    font-style: normal;
  }

  .blank-page {
    page: blank;
  }

  @page blank {
    @bottom-center {
      content: normal;
    }
    @top-center {
      content: normal;
    }
  }

  @page:blank {
    @bottom-center {
      content: normal;
    }
    @top-center {
      content: normal;
    }
  }

  .embedded-content-document {
    margin-left: 1.3em;
    font-family: 'Times New Roman', Palatino, Arial, serif;
  }

  .chapter-heading {
    string-set: chapter attr(data-short);
  }

  a.__xref {
    text-decoration: none;
    color: black;
  }

  a.__xref-linkable-back {
    display: none;
  }



  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .verse-stanza {
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
  }

  .signed-section-signature,
  .signed-section-context-close,
  blockquote + figcaption {
    page-break-before: avoid;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .letter-heading,
  .salutation,
  .signed-section-context-open {
    page-break-after: avoid;
    -webkit-column-break-after: avoid;
  }

  .chapter--no-signed-section p {
    widows: 2;
    orphans: 2;
  }

  .footnote {
    widows: 2;
    orphans: 2;
  }



  .body {
    font-size: 1.06rem;
  }

  p,
  .syllogism li,
  .chapter-synopsis li {
    line-height: 139%;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4 {
    font-size: 1rem;
  }

  h3,
  h4 {
    margin-top: 2.5em;
  }

  .chapter-heading__sequence {
    font-size: 1.1rem;
  }

  .__fn_ignore__-call {
    font-size: 0.83rem;
  }

  .__fn_ignore__-marker {
    font-size: 0.8rem;
  }

  .footnote {
    font-size: 0.85rem;
    line-height: 113%;
  }

  .copyright-page {
    font-size: 0.95rem;
  }

  .half-title-page .byline {
    font-size: 1.3rem;
  }

  .originally-titled__title {
    font-size: 1.3rem;
  }

  blockquote > p,
  blockquote .numbered-group p {
    line-height: 137%;
    padding-left: 1.65em;
  }

  blockquote {
    margin-right: 0;
    margin-left: 1em;
    border-left: dotted 1px #bbb;
  }

  figure.attributed-quote figcaption {
    text-align: right;
    font-style: italic;
    font-size: 0.85rem;
    margin-bottom: 1.5em;
    margin-top: -1em;
  }



  .half-title-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    page: half-title;
  }

  .half-title-page .byline {
    font-style: italic;
    padding-top: 13pt;
  }

  .half-title-page .editor {
    transform: translateY(3in);
  }

  .half-title-page > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  @page half-title {
    @bottom-center {
      content: normal;
    }
  }



  .original-title-page {
    text-align: center;
    padding-top: 5rem;
    page: original-title;
  }

  .originally-titled__label {
    font-style: italic;
  }

  @page original-title {
    @bottom-center {
      content: normal;
    }

    @top-center {
      content: normal;
    }
  }



  .copyright-page {
    page: copyright;
    display: flex;
  }

  .copyright-page a {
    color: black;
    text-decoration: none;
    font-style: italic;
  }

  .copyright-page ul {
    list-style-type: none;
    padding: 0;
    margin: auto 0 0 0;
  }

  .copyright-page ul li {
    padding-bottom: 0.85em;
  }

  .copyright-page ul li.debug {
    font-size: 0.8em;
  }

  @page copyright {
    @bottom-center {
      content: normal;
    }
    @top-center {
      content: normal;
    }
  }



  .toc {
    page: toc;
    page-break-before: recto; /* keep TOC on recto */
  }

  .toc h1 {
    padding-top: 15%;
    font-size: 1.4rem;
    margin-bottom: 2.5rem;
  }

  .toc a {
    text-decoration: none;
    color: black;
  }

  .toc a > span {
    display: inline-block;
    width: 85%;
  }

  .toc a[href]::after {
    content: leader(' ') target-counter(attr(href), page);
    vertical-align: top;
  }

  .toc-intermediate-title a[href]::after {
    content: '';
  }

  @page toc {
    @bottom-center {
      content: normal;
    }
    @top-center {
      content: normal;
    }
  }

  .toc-intermediate-title {
    text-align: center;
    font-variant: small-caps;
    font-size: 95%;
    line-height: 100%;
    margin: 2em 0 0 0;
  }

  .toc-intermediate-title + p {
    margin-top: 0;
  }

  .multicol-toc-entry > a {
    display: flex;
  }

  .multicol-toc-entry .multicol-toc-chapter {
    flex-basis: 2.5em;
    text-align: center;
  }

  .multicol-toc-entry .multicol-toc-main {
    padding: 0 2em 0 1em;
  }



  h2 {
    margin-bottom: 24pt;
  }

  h2.chapter-heading__sequence {
    margin-bottom: 0.65rem;
  }

  .chapter-heading__title {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }



  html {
    font-size: 10pt;
  }

  @page {
    size: 5.5in 8.5in;
    margin-top: 0.85in;
    margin-bottom: 0.65in;

    @top-center {
      margin-top: 0.35in;
    }
  }

  @page:left {
    margin-left: 0.6in;
    margin-right: 0.7in;
  }

  @page:right {
    margin-left: 0.7in;
    margin-right: 0.6in;
  }

  .chapter {
    /* forces new page & allows target @page:first for pages starting chapters */
    prince-page-group: start;
    page-break-before: recto; /* keep start of chapters on recto */
    margin-top: 2.125in;
  }

  .chapter.intermediate-title {
    margin-top: 1.75in;
  }

  .copyright-page {
    height: 7in;
  }

  .half-title-page {
    height: 4in;
  }

  blockquote > p,
  blockquote .numbered-group p {
    font-size: 0.98rem;
  }

  .chapter blockquote > p + p,
  .chapter blockquote .numbered-group p + p {
    margin-top: 0.4em;
  }

  .chapter-1 {
    counter-reset: page 1;
  }



  .intermediate-title {
    page: intermediatetitle;
  }

  @page intermediatetitle {
    @bottom-center {
      content: normal;
    }
  }

  .intermediate-title > *,
  .intermediate-title .paragraph {
    line-height: 150%;
    text-align: center;
  }

  .intermediate-title .chapter-heading h2 {
    font-size: 2rem;
    margin-bottom: 18pt;
  }

  .intermediate-title h3.division {
    font-size: 1.4rem;
    font-variant: small-caps;
    margin-bottom: 3.75rem;
  }
