h2, h3, h4, h5 {
  line-height: 1.5em;
}

h2 {
  text-align: center;
}

.heading-segment {
  line-height: 1.5em;
  display: block;
}

.chapter-heading .heading-segment--2 {
  font-size: .9em;
}

.chapter-heading .heading-segment--3 {
  font-size: .8em;
}

.chapter-heading__sequence {
  font-weight: 400;
}

.chapter-heading__title {
  text-align: center;
  font-weight: 700;
}

.style-blurb .chapter-heading h2 {
  text-transform: none;
  font-style: italic;
  font-weight: normal;
}

.chapter-subtitle--blurb {
  text-transform: none;
  text-align: center;
  margin: -1em 0 2.25em;
  font-style: italic;
  font-weight: normal;
}

.epigraphs {
  text-align: center;
  page: epigraphs;
  margin-top: 30%;
}

.epigraph__text:before {
  content: "“";
}

.epigraph__text:after {
  content: "”";
  margin-left: -.25em;
}

@page epigraphs {
  @bottom-center {
    content: normal;
  }
}

.epigraph {
  font-style: italic;
}

.epigraph__source {
  white-space: nowrap;
  font-size: .9em;
}

.epigraph__source:before {
  content: "(";
}

.epigraph__source:after {
  content: ")";
}

.epigraph--not-first:before {
  content: "*     *     *";
  margin-top: 2.5em;
  padding-bottom: 2em;
  display: block;
}

.poetry {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 95%;
  font-style: italic;
  display: block;
}

.verse-stanza {
  display: block;
}

.verse-stanza + .verse-stanza {
  margin-top: 1.4em;
}

.verse-line {
  line-height: 1.6em;
  display: block;
}

.letter-heading {
  text-transform: uppercase;
  text-align: center;
  text-indent: 0;
  margin: 2.6em 0 .6em;
  font-size: .85em;
  font-weight: 700;
}

.no-indent {
  text-indent: 0;
  text-align: justify;
}

figcaption > :first-child:before {
  content: "—";
}

blockquote .paragraph:first-of-type {
  text-indent: 0;
}

blockquote.scripture p {
  font-style: italic;
}

figure.attributed-quote {
  margin: 0;
}

figure.attributed-quote figcaption cite {
  display: block;
}

h3.blurb, h4.blurb, p.blurb {
  text-align: center;
  font-style: italic;
  font-weight: 400;
}

.style-blurb .chapter-heading .book-title, blockquote.scripture em, .blurb .book-title, .emphasized .book-title, .syllogism em, .emphasized em, .blurb em, .emphasized em, .section-author em, .section-summary-preface .book-title, .section-author-context .book-title, .heading-continuation-blurb .book-title, .section-summary-preface em, .section-author-context em, .heading-continuation-blurb em {
  font-variant: small-caps;
  padding-left: .1em;
  font-size: 1.07em;
  font-style: normal;
  hyphens: none !important;
}

.chapter-synopsis {
  text-align: center;
  margin: 0 1.5em 2em;
  padding: 0;
  font-size: .85em;
  font-style: italic;
  list-style: none;
}

.chapter-synopsis li {
  display: inline;
}

.chapter-synopsis li + li:before {
  content: "—";
  display: inline-block;
}

.offset, .paragraph.offset {
  text-indent: 0;
  text-align: justify;
  margin-top: 1em;
  margin-bottom: 1em;
}

.numbered p:first-child {
  text-indent: 0;
  margin-top: 1em;
  margin-bottom: 0;
}

.numbered-group {
  margin-bottom: 1em;
}

.discourse-part {
  text-align: justify;
  margin-bottom: 0;
}

.small-break {
  height: 1em;
}

.the-end {
  text-transform: uppercase;
  text-align: center;
  margin-top: 3em;
}

.old-style {
  text-align: center;
  margin-top: 2.75em;
}

.old-style .heading-segment--1 {
  margin-bottom: .5em;
}

.old-style .heading-segment {
  line-height: 1.5em;
  display: block;
}

.old-style .heading-segment + .heading-segment {
  font-style: italic;
  font-weight: 400;
}

.old-style.bold .heading-segment + .heading-segment {
  font-style: normal;
  font-weight: 700;
}

.old-style.bold .heading-segment + .heading-segment em {
  font-style: normal;
}

h3.alt, h4.alt {
  font-style: italic;
  font-weight: 400;
}

.centered {
  text-align: center !important;
  text-indent: 0 !important;
}

.emphasized {
  font-style: italic;
}

.weight-normal {
  font-weight: 400;
}

.small-caps {
  font-variant: small-caps;
}

dl {
  margin-top: 1.5em;
}

dd {
  text-align: justify;
  margin: 1em 0 1em 2.85em;
}

dt {
  font-weight: 700;
}

.embedded-content-document {
  margin: 1.35em 0;
}

.half-title-page .editor {
  text-align: center;
  font-size: .65em;
  font-style: italic;
}

.book-title {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.syllogism {
  margin-left: 2.5em;
  padding-left: 0;
}

.syllogism li {
  margin: 1em 0;
  font-style: italic;
}

.heading-continuation-blurb, .section-summary-preface {
  text-indent: -1.5em;
  text-align: justify;
  padding-left: 1.5em;
  font-style: italic;
}

.heading-continuation-blurb {
  margin-bottom: 1.75em;
  font-size: .95em;
}

.section-summary-preface {
  margin-bottom: 1em;
}

.heading-continuation-blurb + .heading-continuation-blurb {
  margin-top: -.75em;
}

.section-author, .section-author-context {
  font-style: italic;
  text-align: center !important;
  text-indent: 0 !important;
}

.section-author-context {
  margin-top: .65em;
  margin-bottom: 1.75em;
  font-size: 90%;
}

.section-author + .heading-continuation-blurb, .section-author-context + .heading-continuation-blurb {
  margin-top: 1.5em;
}

.section-date {
  margin: 1.5em 0;
  text-align: center !important;
  text-indent: 0 !important;
}

.section-date:before {
  content: "[";
  padding-right: .1em;
  font-style: italic;
}

.section-date:after {
  content: "]";
  margin-left: -.2em;
  font-style: italic;
}

blockquote.section-epigraph {
  border-left-width: 0;
  margin-left: 1.5em;
  margin-right: 1.5em;
}

blockquote.section-epigraph .paragraph {
  padding-left: 0;
  font-size: .9em;
}

h3.inline, h4.inline, h5.inline {
  text-indent: -1.5em;
  text-align: justify;
  margin: 1.333em 0;
  padding-left: 1.5em;
  font-size: 1em;
}

h3.inline em, h4.inline em, h5.inline em {
  font-variant: small-caps;
  font-size: 1.07em;
  font-style: normal;
  hyphens: none !important;
}

.footnote-paragraph + .footnote-paragraph {
  margin-top: .5em;
  display: block;
}

.footnote .poetry {
  margin-top: .4em;
  margin-bottom: .4em;
}

.footnote .verse-stanza {
  margin-bottom: 0;
}

.footnote .verse-stanza + .verse-stanza {
  margin-top: .45em;
}

.footnote .verse-line {
  line-height: 1.2em;
}

h3 + .letter-heading {
  margin-top: 1em;
}

h3 + h4 {
  margin-top: -.75em;
}

h3 + h4.old-style {
  margin-top: .75em;
}

h3 + h3, h4 + h4 {
  margin-top: -.5em;
}

.signed-section-context-open {
  margin-top: 2em;
  margin-bottom: 0;
}

.signed-section-context-open + .paragraph {
  margin-top: .75em;
}

.signed-section-context-open, .signed-section-context-close {
  font-size: .9em;
  font-style: italic;
}

.signed-section-context-close {
  margin-bottom: 2.5em;
}

.signed-section-context-close + .signed-section-signature {
  margin-top: -1.5em;
}

.signed-section-closing {
  text-align: right;
  margin-top: .5em;
}

.signed-section-closing + .signed-section-signature {
  margin-top: -.5em;
}

.salutation {
  margin-top: 1em;
  margin-bottom: .5em;
}

.signed-section-context-open + .salutation {
  margin-top: .75em;
}

.signed-section-context-open + .signed-section-context-open {
  margin-top: .1em;
}

.signed-section-signature {
  font-variant: small-caps;
  margin-top: .45em;
  margin-bottom: 2.5em;
  padding-left: 3em;
}

.signed-section-signature:before {
  content: "—";
}

.paragraph + .postscript {
  margin-top: 1em;
}

.signed-section-context-close + .postscript, .signed-section-signature + .postscript {
  margin-top: -1.85em;
}

.postscript .paragraph:first-of-type {
  text-indent: 0;
}

.signed-section-signature, .signed-section-context-close {
  text-align: right;
  text-indent: 0;
}

.salutation {
  margin-bottom: .5em;
}

.letter-heading + .signed-section-context-open {
  margin-top: .5em;
}

.signed-section-context-close + .signed-section-context-close, .signed-section-signature + .signed-section-signature {
  margin-top: -2.5em;
}

.signed-section-signature + .signed-section-context-close {
  margin-top: -2.25em;
}

.offset + .embedded-content-document {
  margin-top: -.5em;
}

.offset + .embedded-content-document .letter-heading {
  margin-top: 1.75em;
}

table.tableblock {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
}

table.tableblock p.tableblock {
  margin: 0;
  line-height: 115%;
}

table.tableblock th + th, table.tableblock td + td {
  padding-left: 1em;
}

table.tableblock.table-vertical-lines th + th, table.tableblock.table-vertical-lines td + td {
  padding: 0 .2em;
}

table.tableblock.table-vertical-lines td:first-child, table.tableblock.table-vertical-lines th:first-child {
  padding-right: .2em;
}

table.tableblock.table-vertical-lines td:last-child, table.tableblock.table-vertical-lines th:last-child {
  padding-left: .2em;
}

table.tableblock.table-vertical-lines tr:last-child, table.tableblock.table-vertical-lines thead {
  border-bottom-width: 0;
}

table.tableblock.table-vertical-lines td + td {
  border-left: 1px solid #0003;
}

table.tableblock tr {
  vertical-align: bottom;
  border-bottom: 1px solid #0003;
}

table.tableblock td, table.tableblock th {
  vertical-align: bottom;
  padding-top: .25em;
  padding-bottom: .25em;
}

table.tableblock.table-valign-middle td, table.tableblock.table-valign-middle th {
  vertical-align: middle;
}

table.tableblock tr:last-child {
  border-bottom: 1px solid #000000bf;
}

table.tableblock thead {
  border-bottom: 1px solid #000000bf;
  padding-bottom: .25em;
}

table.tableblock + table.tableblock {
  margin-top: 2em;
}

table.tableblock.table-align-center td, table.tableblock.table-align-center td > *, table.tableblock.table-tail-align-center td, table.tableblock.table-tail-align-center th {
  text-align: center;
}

table.tableblock.table-tail-align-right td, table.tableblock.table-tail-align-right th {
  text-align: right;
}

table.tableblock.table-tail-align-center td:first-child, table.tableblock.table-tail-align-center th:first-child, table.tableblock.table-tail-align-right td:first-child, table.tableblock.table-tail-align-right th:first-child {
  text-align: left;
}

table.tableblock.table-header-tail-x-small th {
  font-size: .74em;
}

table.tableblock.table-header-tail-x-small th:first-child {
  font-size: inherit;
}

table.tableblock p.tableblock + p.tableblock {
  margin-top: 1em;
}

table.tableblock.table-last-col-secondary td:last-child {
  font-size: .85em;
  font-style: italic;
}

@page {
  @bottom-center {
    content: counter(page);
    font-size: .9rem;
    vertical-align: text-top;
    margin-top: 5pt;
    padding: 0 !important;
  }

  @top-center {
    margin-top: .35in;
    font-size: .9rem;
    font-style: italic;
  }
}

@page :left {
  @top-center {
    content: "Preview";
  }
}

@page :right {
  @top-center {
    content: string(chapter, last);
  }
}

@page :first {
  @top-center {
    content: normal;
  }
}

.letter-heading {
  hyphens: none !important;
}

.body {
  font-family: Georgia, Bitstream Charter, serif;
}

div.asterism {
  text-align: center;
  letter-spacing: .15em;
  vertical-align: middle;
  margin: 1em 0 .6em;
  line-height: 2em;
}

.paragraph {
  text-indent: 1.5em;
  text-align: justify;
  hyphens: auto;
  margin: 0;
}

.own-page {
  page-break-before: always;
  page-break-after: always;
}

#footnotes {
  display: none;
}

.chapter {
  counter-reset: footnote;
}

.__fn_ignore__-call {
  content: counter(footnote);
  vertical-align: super;
  line-height: none;
}

.__fn_ignore__-marker {
  content: counter(footnote);
  opacity: .9;
  padding-right: 7pt;
}

.footnote {
  float: footnote;
  text-indent: 0;
  padding-top: .5em;
  font-style: normal;
  font-weight: 400;
  text-align: justify !important;
  text-transform: none !important;
  font-variant: normal !important;
}

.blank-page {
  page: blank;
}

@page blank {
  @bottom-center {
    content: normal;
  }

  @top-center {
    content: normal;
  }
}

@page :blank {
  @bottom-center {
    content: normal;
  }

  @top-center {
    content: normal;
  }
}

.embedded-content-document {
  margin-left: 1.3em;
  font-family: Times New Roman, Palatino, Arial, serif;
}

.chapter-heading {
  string-set: chapter attr(data-short);
}

a.__xref {
  color: #000;
  text-decoration: none;
}

a.__xref-linkable-back {
  display: none;
}

h1, h2, h3, h4, h5, h6, .verse-stanza {
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
}

.signed-section-signature, .signed-section-context-close, blockquote + figcaption {
  page-break-before: avoid;
}

h1, h2, h3, h4, h5, h6, .letter-heading, .salutation, .signed-section-context-open {
  page-break-after: avoid;
  -webkit-column-break-after: avoid;
}

.chapter--no-signed-section p, .footnote {
  widows: 2;
  orphans: 2;
}

.body {
  font-size: 1.06rem;
}

p, .syllogism li, .chapter-synopsis li {
  line-height: 139%;
}

h2 {
  font-size: 1.2rem;
}

h3 {
  font-size: 1.1rem;
}

h4 {
  font-size: 1rem;
}

h3, h4 {
  margin-top: 2.5em;
}

.chapter-heading__sequence {
  font-size: 1.1rem;
}

.__fn_ignore__-call {
  font-size: .83rem;
}

.__fn_ignore__-marker {
  font-size: .8rem;
}

.footnote {
  font-size: .85rem;
  line-height: 113%;
}

.copyright-page {
  font-size: .95rem;
}

.half-title-page .byline, .originally-titled__title {
  font-size: 1.3rem;
}

blockquote > p, blockquote .numbered-group p {
  padding-left: 1.65em;
  line-height: 137%;
}

blockquote {
  border-left: 1px dotted #bbb;
  margin-left: 1em;
  margin-right: 0;
}

figure.attributed-quote figcaption {
  text-align: right;
  margin-top: -1em;
  margin-bottom: 1.5em;
  font-size: .85rem;
  font-style: italic;
}

.half-title-page {
  text-align: center;
  page: half-title;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.half-title-page .byline {
  padding-top: 13pt;
  font-style: italic;
}

.half-title-page .editor {
  transform: translateY(3in);
}

.half-title-page > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@page half-title {
  @bottom-center {
    content: normal;
  }
}

.original-title-page {
  text-align: center;
  page: original-title;
  padding-top: 5rem;
}

.originally-titled__label {
  font-style: italic;
}

@page original-title {
  @bottom-center {
    content: normal;
  }

  @top-center {
    content: normal;
  }
}

.copyright-page {
  page: copyright;
  display: flex;
}

.copyright-page a {
  color: #000;
  font-style: italic;
  text-decoration: none;
}

.copyright-page ul {
  margin: auto 0 0;
  padding: 0;
  list-style-type: none;
}

.copyright-page ul li {
  padding-bottom: .85em;
}

.copyright-page ul li.debug {
  font-size: .8em;
}

@page copyright {
  @bottom-center {
    content: normal;
  }

  @top-center {
    content: normal;
  }
}

.toc {
  page: toc;
  page-break-before: recto;
}

.toc h1 {
  margin-bottom: 2.5rem;
  padding-top: 15%;
  font-size: 1.4rem;
}

.toc a {
  color: #000;
  text-decoration: none;
}

.toc a > span {
  width: 85%;
  display: inline-block;
}

.toc a[href]:after {
  content: leader(" ") target-counter(attr(href), page);
  vertical-align: top;
}

.toc-intermediate-title a[href]:after {
  content: "";
}

@page toc {
  @bottom-center {
    content: normal;
  }

  @top-center {
    content: normal;
  }
}

.toc-intermediate-title {
  text-align: center;
  font-variant: small-caps;
  margin: 2em 0 0;
  font-size: 95%;
  line-height: 100%;
}

.toc-intermediate-title + p {
  margin-top: 0;
}

.multicol-toc-entry > a {
  display: flex;
}

.multicol-toc-entry .multicol-toc-chapter {
  text-align: center;
  flex-basis: 2.5em;
}

.multicol-toc-entry .multicol-toc-main {
  padding: 0 2em 0 1em;
}

h2 {
  margin-bottom: 24pt;
}

h2.chapter-heading__sequence {
  margin-bottom: .65rem;
}

.chapter-heading__title {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

html {
  font-size: 10pt;
}

@page {
  size: 5.5in 8.5in;
  margin-top: .85in;
  margin-bottom: .65in;

  @top-center {
    margin-top: .35in;
  }
}

@page :left {
  margin-left: .6in;
  margin-right: .7in;
}

@page :right {
  margin-left: .7in;
  margin-right: .6in;
}

.chapter {
  prince-page-group: start;
  page-break-before: recto;
  margin-top: 2.125in;
}

.chapter.intermediate-title {
  margin-top: 1.75in;
}

.copyright-page {
  height: 7in;
}

.half-title-page {
  height: 4in;
}

blockquote > p, blockquote .numbered-group p {
  font-size: .98rem;
}

.chapter blockquote > p + p, .chapter blockquote .numbered-group p + p {
  margin-top: .4em;
}

.chapter-1 {
  counter-reset: page 1;
}

.intermediate-title {
  page: intermediatetitle;
}

@page intermediatetitle {
  @bottom-center {
    content: normal;
  }
}

.intermediate-title > *, .intermediate-title .paragraph {
  text-align: center;
  line-height: 150%;
}

.intermediate-title .chapter-heading h2 {
  margin-bottom: 18pt;
  font-size: 2rem;
}

.intermediate-title h3.division {
  font-variant: small-caps;
  margin-bottom: 3.75rem;
  font-size: 1.4rem;
}
/*# sourceMappingURL=index.62a8dbb5.css.map */
